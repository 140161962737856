import Vue from 'vue'
import Axios from 'axios'

import router from '../router'

Axios.defaults.withCredentials = true

const axios = Axios.create({
    baseURL: '',
    timeout: 100000,
    withCredentials: true,
    crossDomain: true
})

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(res => {
    if (res.data) {
        return res.data
    }
    return res
}, error => {
    return Promise.reject(error)
})

export default () => {
    Vue.prototype.$axios = axios
}