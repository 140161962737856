<template>
  <el-container>
    <el-header class="" style="height: 80px;">
      <LayoutHeader></LayoutHeader>
    </el-header>
    <el-main>
      <router-view></router-view>
    </el-main>
    <el-footer style="background-color: #333;height: auto">
      <LayoutFooter v-if="$route.name==='home'||$route.name==='aboutUs'"></LayoutFooter>
      <div v-else class="py-15 tc-white ta-c">Copyright© 南京亿儒科技有限公司 苏ICP备2022045050号 {{ copyright }}</div>
    </el-footer>
  </el-container>
</template>

<script>
import LayoutHeader from "./layoutHeader.vue";
import LayoutFooter from "./layoutFooter.vue";

export default {
  name: "index",
  components: {
    LayoutHeader,
    LayoutFooter
  },
  data() {
    return {
      copyright: ''
    }
  },
  created() {
    let info = require("../../../package.json");
    this.copyright = 'V' + info.version;
  }
}
</script>

<style scoped lang="scss">
.el-header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.el-main {
  padding: 0;
}
</style>