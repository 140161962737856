<template>
  <el-row :gutter="50" class="tc-white" style="padding: 50px 300px 100px;margin: 0">
    <el-col :span="8">
      <div class="fs-18">南京亿儒科技有限公司</div>
      <div class="divider-line"></div>
      <div>助力汽渡行业实现数字化转型</div>
    </el-col>
    <el-col :span="8">
      <div class="fs-18">联系我们</div>
      <div class="divider-line"></div>
      <div class="mb-12">地址：南京市秦淮区光华路162号 拉萨产业交流中心 6层623</div>
      <div class="mb-12">联系人：胡先生</div>
      <div class="mb-12">电话：17372281261</div>
      <div>邮箱：njyirukeji@163.com</div>
    </el-col>
    <el-col :span="8">
      <div class="fs-18">关注公众号</div>
      <div class="divider-line"></div>
      <div>
        <img lazy src="@/assets/img/qrcode.jpg" width="150" alt="">
      </div>
    </el-col>
    <div class="copyrightCss">Copyright© 南京亿儒科技有限公司 苏ICP备2022045050号 {{copyright}}</div>
  </el-row>
</template>

<script>
export default {
  name: "layoutFooter",
  data() {
    return {
      copyright: ''
    }
  },
  created() {
    let info = require("../../../package.json");
    this.copyright = 'V' + info.version;
  }
}
</script>

<style scoped lang="scss">
.divider-line {
  height: 2px;
  width: 100px;
  background: #fff;
  margin: 10px 0 20px;
}
.copyrightCss {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #777;
}
</style>