import router from './router'
import axios from 'axios'

router.beforeEach((to, from, next) => {
  if (!sessionStorage.userinfo) {
    if (to.query.code) {
      let params = {
        code: to.query.code
      }
      axios
        .get('/getOpenId', {params: params})
        .then(res => {
          if (res.data.code === 200) {
            sessionStorage.userinfo = JSON.stringify(res.data.data);
            next();
          } else {
            next('/login');
          }
        }).catch(err => {
          next('/login');
          console.log(err);
        });
    } else {
      next();
    }
  } else {
    // if (to.name === 'login') next('/home');
    if (to.name === 'login') next('/system');
    next();
  }
})