import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'

Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/',
    component: Layout,
    // redirect: 'home',
    redirect: 'system',
    meta: {
      title: '业务办理'
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/system/index'),
        meta: {
          title: '业务办理'
        }
      },
      {
        path: 'system',
        name: 'system',
        component: () => import('@/views/system/index'),
        meta: {
          title: '业务办理'
        }
      },
      {
        path: 'makeInvoice',
        name: 'makeInvoice',
        component: () => import('@/views/makeInvoice/index'),
        redirect: 'makeInvoice/carInfo',
        meta: {
          title: '开票'
        },
        children: [
          {
            path: 'carCertification/:item',
            name: 'carCertification',
            component: () => import('@/views/makeInvoice/carCertification'),
            meta: {
              title: '车辆认证'
            }
          },
          {
            path: 'transitionCertification/:item',
            name: 'transitionCertification',
            component: () => import('@/views/makeInvoice/transitionCertification'),
            meta: {
              title: '过渡信息认证'
            }
          },
          {
            path: 'carInfo',
            name: 'carInfo',
            component: () => import('@/views/makeInvoice/carInfo'),
            meta: {
              title: '车辆信息'
            }
          },
          {
            path: 'headerInfo',
            name: 'headerInfo',
            component: () => import('@/views/makeInvoice/headerInfo'),
            meta: {
              title: '抬头信息'
            }
          },
          {
            path: 'invoice/:type/:item',
            name: 'invoice',
            component: () => import('@/views/makeInvoice/invoice'),
            meta: {
              title: '开具发票'
            }
          },
          {
            path: 'invoiceInfo',
            name: 'invoiceInfo',
            component: () => import('@/views/makeInvoice/invoiceInfo'),
            redirect: 'invoiceInfo/notInvoiceList',
            meta: {
              title: '开票信息'
            },
            children: [
              {
                path: 'notInvoiceList',
                name: 'notInvoiceList',
                component: () => import('@/views/makeInvoice/notInvoiceList'),
                meta: {
                  title: '待开发票'
                }
              },
              {
                path: 'invoiceList/:status',
                name: 'invoiceList',
                component: () => import('@/views/makeInvoice/invoiceList'),
                meta: {
                  title: '开票记录'
                }
              }
            ]
          }
        ]
      },
      // {
      //   path: 'appCase',
      //   name: 'appCase',
      //   component: () => import('@/views/appCase/index'),
      //   meta: {
      //     title: '解决方案'
      //   }
      // },
      // {
      //   path: 'news',
      //   name: 'news',
      //   component: () => import('@/views/news/index'),
      //   meta: {
      //     title: '新闻动态'
      //   }
      // },
      // {
      //   path: 'aboutUs',
      //   name: 'aboutUs',
      //   component: () => import('@/views/aboutUs/index'),
      //   meta: {
      //     title: '关于亿儒'
      //   }
      // },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
