<template>
  <el-row class="px-50 tc-white" style="line-height: 80px">
    <el-col :span="4" class="pt-15">
      <img src="@/assets/img/YRlogo.png" height="50" alt="" />
    </el-col>
    <!-- 菜单 -->
    <el-col :span="16">
      <el-menu
        :default-active="$route.name"
        mode="horizontal"
        text-color="#fff"
        active-text-color="#006ED3"
        background-color="transparent"
        @select="handleSelect"
      >
        <template v-for="menu in menuList">
          <el-menu-item
            :class="{ active: getActive(menu), highlight: getCrtName(menu) }"
            v-if="menu.level === 1"
            :index="menu.name"
          >
            {{ menu.label }}
          </el-menu-item>
          <el-submenu v-if="menu.level === 2" :index="menu.name">
            <template slot="title">{{ menu.label }}</template>
            <template v-for="m in menu.children" :index="m.name">
              <el-menu-item
                :class="{ active: getActive(menu, m) }"
                class="fs-16"
                >{{ m.label }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
      </el-menu>
    </el-col>
    <!-- 用户信息 -->
    <el-col :span="4" class="ta-r fs-22">
      <div v-if="!isLogin" class="cp" @click="handleSelect('login')">
        <img
          style="position: relative; top: 8px"
          src="@/assets/img/userIcon.png"
          alt=""
        />
        <span class="mx-10">登录</span>
      </div>
      <div v-else>
        <el-dropdown @command="handleCommand" style="line-height: 30px">
          <span class="el-dropdown-link cp tc-white">
            <el-avatar
              v-if="userinfo.headimgurl"
              :size="36"
              :src="userinfo.headimgurl"
              class="el-icon-s-custom"
              style="position: relative; top: 12px"
            ></el-avatar>
            <img
              v-else
              style="position: relative; top: 8px"
              src="@/assets/img/userIcon.png"
              alt=""
            />
            <span class="mx-10 fs-18">{{ userinfo.nickname || "用户" }}</span>
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personalCenter">注册</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-col>
    <!-- 点击注册弹出注册框 -->
    <el-dialog
      title="注册"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="400px"
      append-to-body
    >
      <el-form :model="registerForm" :rules="formRules" ref="registerForm">
        <el-form-item label="姓名" label-width="100px" prop="user_name">
          <el-input
            v-model="registerForm.user_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="phone">
          <el-input v-model="registerForm.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色" label-width="100px" prop="role">
          <el-select v-model="registerForm.role" placeholder="请选择角色">
            <el-option label="汽渡人员" value="0"></el-option>
            <el-option label="代理商" value="1"></el-option>
            <el-option label="普通用户" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="汽渡名称" v-if="registerForm.role != '2'" label-width="100px" prop="station_id">
          <el-select
            v-model="registerForm.station_id"
            multiple
            placeholder="请选择汽渡"
          >
            <el-option
              v-for="item in stationList"
              :key="item.spare2"
              :label="item.stationName"
              :value="item.spare2"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="registerConfirm()">确 定</el-button>
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
export default {
  name: "layoutHeader",
  data() {
    return {
      isLogin: sessionStorage.userinfo,
      userinfo: {},
      menuList: [
        // {
        //   level: 1,
        //   name: "home",
        //   label: "首页",
        // },
        {
          level: 1,
          name: "system",
          label: "业务办理",
        },
        // {
        //   level: 1,
        //   name: 'makeInvoice',
        //   label: '我要开票'
        // },
        // {
        //   level: 1,
        //   name: "appCase",
        //   label: "解决方案",
        // },
        // {
        //   level: 1,
        //   name: "news",
        //   label: "新闻动态",
        // },
        // {
        //   level: 1,
        //   name: "aboutUs",
        //   label: "关于亿儒",
        // },
      ],
      //注册弹出框的
      dialogFormVisible: false,

      registerForm: {
        unionid: "",
        user_name: "",
        phone: "",
        role: "",
        station_id: "",
      },

      //校验
      formRules: {
        user_name: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              // 定义手机号的正则表达式
              const phoneRegex = /^[1][0-9]{10}$/;

              // 使用正则表达式进行匹配
              if (!phoneRegex.test(value)) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback(); // 验证通过
              }
            },
            trigger: "blur", // 触发校验的事件
          },
        ],
        role: [{ required: true, message: "角色不能为空", trigger: "change" }],
        // station_id: [
        //   { required: true, message: "汽渡名称不能为空", trigger: "blur" },
        // ],
      },

      //站号数组
      stationList: [],
    };
  },
  mounted() {
    this.userinfo = sessionStorage.userinfo
      ? JSON.parse(sessionStorage.userinfo)
      : {};
    this.wechatpublicaccount();
  },

  methods: {
    //点击取消
    cancel() {
      this.dialogFormVisible = false;
      this.registerForm = {};
    },

    //点击确定按钮
    registerConfirm() {
      this.$refs["registerForm"].validate((valid) => {
        if (valid) {
          // console.log("通过");
          this.dialogFormVisible = false;
          this.registerForm.unionid = JSON.parse(
            sessionStorage.userinfo
          ).unionid;
          console.log(this.registerForm);
          this.$axios
            .post("/yrservice/website/registerRecords/", this.registerForm)
            .then((res) => {
              const { code, data, msg } = res;

              this.registerForm = {};
              if (code === 0) {
                //成功
                this.$message({
                  message: "注册成功",
                  type: "success", // 消息类型：成功
                });
                return;
              } else {
                //失败
                this.$message({
                  message: "注册失败",
                  type: "error", // 消息类型：失败
                });
                return;
              }
            })
            .catch((error) => {
              this.$message({
                message: error,
                type: "error", // 消息类型：失败
              });
              return;
            });
        } else {
          // console.log("不通过");
        }
      });
    },

    handleSelect(key, keyPath) {
      if (key === this.$route.name) return;
      if (key === "login") sessionStorage.fromRoute = window.location.href;
      this.$router.push({
        name: key,
      });
    },
    getActive(menu, item) {
      return menu.name === this.$route.path.split("/")[1];
    },
    getCrtName(menu) {
      return menu.name === "makeInvoice";
    },
    handleCommand(command) {
      if (command === "logout") {
        this.logout();
      } else if (command === "personalCenter") {
        // console.log("点击了注册按钮");
        this.dialogFormVisible = true;
      }
    },

    logout() {
      let _this = this;
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        beforeClose(action, instance, done) {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.cancelButtonText = "退出中...";
            if (!sessionStorage.userinfo) done();
            _this.logoutFun(instance, done);
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("退出登录成功！");
          sessionStorage.removeItem("userinfo");
          sessionStorage.removeItem("carInfo");
          sessionStorage.removeItem("fromRoute");
          this.isLogin = false;
          // this.$router.push("/home");
          this.$router.push("/system");
        })
        .catch(() => {
          this.$message.info("已取消");
        });
    },
    logoutFun(instance, done) {
      this.$axios
        .post("/yrservice/wxLogin/logOutOfLogin/", {
          unionid: JSON.parse(sessionStorage.userinfo).unionid,
        })
        .then((res) => {
          const { msg, code } = res.data;
          if (code !== 0) {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "确定";
            if (code === 403) return;
            done();
          }
          instance.confirmButtonLoading = false;
          instance.confirmButtonText = "确定";
          done();
        })
        .catch((error) => {
          instance.confirmButtonLoading = false;
          instance.confirmButtonText = "确定";
          done();
        });
    },
    //查询站号
    wechatpublicaccount() {
      this.$axios
        .post("/wechatpublicaccount/journey/getStationConfig", {})
        .then((res) => {
          const { code, success, msg, result } = res;
          // console.log(result);
          if (code === 0) {
            if (result.length > 0) {
              this.stationList = Object.values(
                result.reduce((accumulator, current) => {
                  accumulator[current.stationName] =
                    accumulator[current.stationName] || current;
                  return accumulator;
                }, {})
              );
            }
            // console.log("=================");
            // console.log(this.stationList);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
//.el-menu--horizontal>.el-menu-item,
//.el-menu--horizontal>.el-submenu .el-submenu__title {
//  height: 80px;
//  line-height: 80px;
//}
.el-menu--horizontal {
  ::v-deep {
    .el-menu-item,
    .el-submenu .el-submenu__title {
      height: 80px;
      line-height: 80px;
      font-size: 22px;
      font-weight: 600;
    }
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
  ::v-deep {
    .el-menu-item,
    .el-submenu {
      width: 20%;
      text-align: center;
      background-color: transparent !important;
    }
    .el-menu-item:not(.is-disabled):focus,
    .el-menu-item:not(.is-disabled):hover {
      color: #006ed3 !important;
      color: #003e85 !important;
    }
    .el-menu-item.active {
      color: #006ed3 !important;
      color: #003e85 !important;
    }
    .el-menu-item.highlight {
      //background-color: rgba(0,0,0,0.2) !important;
    }
    .el-menu-item.is-active,
    .el-submenu .el-submenu__title {
      border-bottom: none;
      border-bottom-color: transparent !important;
      background-color: transparent !important;
    }
    .el-submenu__title i {
      color: #fff;
    }
  }
}
</style>
